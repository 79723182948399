import React, {useRef, useState, useEffect} from 'react';
import { Fade } from "react-awesome-reveal";
import "../assets/styles/Contact.scss";
import tile from '../assets/images/tile-contact.png'
import Loader from '../components/Loader';
import DatePicker from "react-datepicker";
import { Helmet } from 'react-helmet-async';
import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

export default function Contact(props) {

    const moment = require('moment');
    const [loading, setLoading] = useState(true);
    //const [showDatepicker, setShowDatepicker] = useState(false);
    //const [numValue, setNumValue] = useState('');
    // const [startDate, setStartDate] = useState();

    // check if iframe is already loaded
    const [iframeIsMounted, setIframeIsMounted] = useState(false);
    const iframeRef = useRef(null);
    const myFrame = document.getElementById('myframe');
    const schemaOrgJSONLD = {
        "@context": "https://schema.org/",
        "@type": "WebContent",
        "description": "Reliable tech support for your business needs",   
        "mainEntityOfPage": "https://www.telavi.com.ph/",
        "name": "Tech Support - Telavi",
        "potentialAction": "Contact Us",        
        "url": "https://www.telavi.com.ph/tech-support/"                     
    };


    
    const handleIframeLoad = () => {
        setIframeIsMounted(true);           
        //myFrame.contentWindow.document.body.scrollWidth;
        if(myFrame) {
            myFrame.height = "";
            myFrame.height = myFrame.contentWindow.document.body.scrollHeight + "px";
            console.log(myFrame.height);
        }
        
    }

    
    
        
    

    //const [excludedDates, setExcludedDates] = useState([]);
    useEffect(() => {
        // const fetchUserData = () => {
            // fetch("https://telavidev-cms.gurlavi.com.ph/contactus/getdates")
            // .then(response => {
            //     return response.json();
            // })
            // .then(data => {
            //     var placeholder = []
            //     data.forEach(item => {
            //         placeholder.push(item["date"]);
            //     });

            //     setExcludedDates(placeholder);
            // })
        // }

        // fetchUserData();
        //console.log(excludedDates);        

        if(iframeIsMounted){
            setLoading(false);
        }
    }, [iframeIsMounted]);




    var date = new Date();
    const [startDate, setStartDate] = useState(
        setHours(setMinutes(date.setDate(date.getDate() + 1), 0), 9)
    );

    const handleChange = event => {
        const limit = 11;
        //setNumValue(event.target.value.slice(0, limit));
    };

    const handleSelect = event => {
        const val = event.target.value;

        if(val === "Book a call appointment"){
            //setShowDatepicker(true);
        }
    }


    return(
        <>
        {loading ? (<Loader />) : ("")}
        <>
        <Helmet>
                <title>Expert Tech Support for Seamless Operations | Telavi</title>
                <meta name="description" content="Ensure smooth operations with expert tech support tailored to your needs. From troubleshooting to system optimization, we keep your business running effortlessly." />
                <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
        </Helmet>
        <section className='contact-page'>
            <div className="section section-form head">
                <h1 className='title text-left'>
                    Tech Support
                    <img src={tile} className='tile'/>
                </h1>
            </div>
            <div className="section section-form body">            
            <iframe id="myframe" onLoad={handleIframeLoad} src="https://4583070-sb1.extforms.netsuite.com/app/site/crm/externalcasepage.nl?compid=4583070_SB1&formid=33&h=AAFdikaInOYVDMb8vl8BPOmOB7dIWrnKvnpxzdkTN0KN8BgOPMw" style={{width:'98%', height:'950px', overflow: 'hidden', border: '0',allowtransparency:true}}></iframe>
                {/*<iframe id="myframe" onLoad={handleIframeLoad} src="https://4583070.extforms.netsuite.com/app/site/crm/externalcasepage.nl/compid.4583070/.f?formid=33&h=AAFdikaIuBYeoRoTxJNiQCXX17f_6j-iX5eVc7klDAO7o9ZqM-c&redirect_count=1&did_javascript_redirect=T" style={{width:'98%', height:'1150px', overflow: 'hidden', border: '0',allowtransparency:true}}></iframe>*/}                
                {/*<iframe id="myframe" onLoad={handleIframeLoad} src="https://4583070-sb1.extforms.netsuite.com/app/site/crm/externalcasepage.nl/compid.4583070_SB1/.f?formid=33&h=AAFdikaInOYVDMb8vl8BPOmOB7dIWrnKvnpxzdkTN0KN8BgOPMw&redirect_count=1&did_javascript_redirect=T" style={{width:'98%', height:'700px', overflow: 'hidden', border: '0',allowtransparency:true}}></iframe>*/}                
            </div>
            <div className="section section-contact-info head">
            </div>
            {/*<div className="section section-contact-info body">
                <img src={tile} className='tile tile-second'/>
                <div className="contact-wrapper">
                <h1 className='title title-blue'>Call Us Now</h1>
                <h1 className="title">+6391X - XXX - XXXX</h1>}
                <h1 className='title title-blue'>Chat with Telavi</h1>
                <div className="btn btn-black">Chat with Sales</div><br />
                <a href="tel:63289670580">
                    <div className="btn btn-black">Schedule a meeting or demo</div>
                </a>
                </div>
            </div> */}
        </section>
        </>
        </>
    );
}