import React, {useRef, useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import Loader from '../components/Loader';
import { Fade, Zoom } from "react-awesome-reveal";
import { useAppContext } from '../libs/contextLib';
import { useParams } from 'react-router';
import "../assets/styles/Product.scss";

import bannertile from "../assets/images/inner-page-tile.png"

export default function Product() {
    const {isPageLight, setIsPageLight} = useAppContext();
    const [loading, setLoading] = useState(true);
    const params= useParams();

    const [articleContent, setArticleContent] = useState();

    const roam_schemaOrgJSONLD = {
        "@context": "https://schema.org/",
        "@type": "IndividualProduct",
        "@id": "https://www.telavi.com.ph/products/roam",   
        "description": "Explore Roam: Advanced solutions for seamless connectivity",
        "name": "Roam",
        "mainEntityOfPage": "https://www.telavi.com.ph/",        
        "isRelatedTo": "digital communication solutions"                     
    };

    const bizconnect_schemaOrgJSONLD = {
        "@context": "https://schema.org/",
        "@type": "IndividualProduct",
        "@id": "https://www.telavi.com.ph/products/bizconnect",   
        "description": "BizConnect: Empowering businesses with seamless connectivity",
        "name": "Bizconnect",
        "mainEntityOfPage": "https://www.telavi.com.ph/",        
        "isRelatedTo": "digital communication solutions"                     
    };

    const cxboost_schemaOrgJSONLD = {
        "@context": "https://schema.org/",
        "@type": "IndividualProduct",
        "@id": "https://www.telavi.com.ph/products/cxboost",   
        "description": "CXBoost: Enhance customer experience with smart solutions",
        "name": "CXboost",
        "mainEntityOfPage": "https://www.telavi.com.ph/",        
        "isRelatedTo": "digital communication solutions"                     
    };

    const click2call_schemaOrgJSONLD = {
        "@context": "https://schema.org/",
        "@type": "IndividualProduct",
        "@id": "https://www.telavi.com.ph/products/click2call",   
        "description": "Click2Call: Simplify customer communication instantly",
        "name": "Click2Call",
        "mainEntityOfPage": "https://www.telavi.com.ph/",        
        "isRelatedTo": "digital communication solutions"                     
    };

    const link_schemaOrgJSONLD = {
        "@context": "https://schema.org/",
        "@type": "IndividualProduct",
        "@id": "https://www.telavi.com.ph/products/link",   
        "description": "Link: Seamless connectivity for your business needs",
        "name": "Link",
        "mainEntityOfPage": "https://www.telavi.com.ph/",        
        "isRelatedTo": "digital communication solutions"                     
    };

    useEffect(() => {
        // setIsPageLight(false);
        console.log(params.slug);
        switch (params.slug) {
            case "click2call":
                setArticleContent(
                    <div>
                        <Helmet>
                                <title>Click2Call: Instant Customer Connection in One Click | Telavi</title>
                                <meta name="description" content="Make it effortless for customers to reach you with Click2Call. One simple click connects them instantly, enhancing customer engagement and boosting satisfaction." />
                                <script type="application/ld+json">{JSON.stringify(click2call_schemaOrgJSONLD)}</script>
                        </Helmet>                    
                        <div className='product-main product-ctc'>
                            <section className='banner inner-page'>
                                <div className="content-wrapper">
                                    <div className="left">
                                        <Fade triggerOnce direction='left' duration={1000} className='tile tile-tl'>
                                            <img src={bannertile} className='inner-banner-tile'/>
                                        </Fade>
                                        <div className="brand">
                                            <Fade cascade direction='right' delay={300} triggerOnce duration={500}>
                                                <h1 className="title">
                                                    Click2Call
                                                </h1>
                                            </Fade>
                                        </div>
                                    </div>
                                    <div className="right imgbanner-wrapper">
                                        <Fade triggerOnce duration={1500}>
                                            <img src={require("../assets/images/products/click2call-banner.jpg")} className='imgbanner'/>
                                        </Fade>
                                    </div>
                                </div>
                            </section>
                            <section className="product-slogan">
                                <div className="content-wrapper">
                                    <div className="left">
                                        <h1 className='title'>Let your Customers Call you with just ONE CLICK</h1>
                                        <img src={require("../assets/images/products/click2call-2.jpg")} alt="" srcSet="" />
                                    </div>
                                    <div className="right">
                                        <img src={require("../assets/images/ctc-badge.png")} alt="" srcSet="" />
                                        <ul>
                                            <li className='check-title'>
                                                <span>
                                                    Elevate Your Customer Interactions
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    One-click plugin for your customers to call your business directly for FREE
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    No more dialing numbers
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    Seamlessly integrate our tool across your social media and websites
                                                </span>
                                            </li>
                                            <li>
                                            <div className="product-features">
                                                <div className="content-wrapper">
                                                    <h1 className='title'>Enhance Customer Experience with every click.</h1>
                                                    {/* <div className="prices">
                                                        <p><b>PRICE STARTS AT PHP 1,500per user/ month</b></p>
                                                        <p>With Telavi App or On Net: Php 1,500</p>
                                                        <p>Off Net: Php 2,500</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )
                break;
            case "bizconnect":
                setArticleContent(
                    <div>
                        <Helmet>
                                <title>BizConnect: One Number for All Your Business Sites | Telavi</title>
                                <meta name="description" content="Simplify communication with BizConnect—link multiple branches using a single number. Enjoy seamless PBX integration through the cloud for unified connectivity." />
                                <script type="application/ld+json">{JSON.stringify(bizconnect_schemaOrgJSONLD)}</script>
                        </Helmet>
                        <div className='product-main product-ctc'>
                            <section className='banner inner-page'>
                                <div className="content-wrapper">
                                    <div className="left">
                                        <Fade triggerOnce direction='left' duration={1000} className='tile tile-tl'>
                                            <img src={bannertile} className='inner-banner-tile'/>
                                        </Fade>
                                        <div className="brand">
                                            <Fade cascade direction='right' delay={300} triggerOnce duration={500}>
                                                <h1 className="title">
                                                    BizConnect
                                                </h1>
                                            </Fade>
                                        </div>
                                    </div>
                                    <div className="right imgbanner-wrapper">
                                        <Fade triggerOnce duration={1500}>
                                            <img src={require("../assets/images/products/bizconnect-banner.jpg")} className='imgbanner'/>
                                        </Fade>
                                    </div>
                                </div>
                            </section>
                            <section className="product-slogan">
                                <div className="content-wrapper">
                                    <div className="left">
                                        <h1 className='title'>Connect Multiple Branches and Office Sites with just ONE Number</h1>
                                        <p>No need for multiple landline numbers per business site or branch because your PABX is now on Cloud.</p>
                                        <img src={require("../assets/images/products/bizconnect-2.jpg")} alt="" srcSet="" />
                                    </div>
                                    <div className="right">
                                        <img src={require("../assets/images/ctc-badge.png")} alt="" srcSet="" />
                                        <ul>
                                            <li className='check-title'>
                                                <span>
                                                    HOW CAN TELAVI BIZCONNECT HELP YOUR BUSINESS?
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    Interconnect your different business sites or branches using the same landline number (no charges for local calls internally)
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    Ease of calling internally just by dialing a local extension number
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    No downtime with 99.6% Uptime Reliability on Cloud
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <section className="product-features">
                                <div className="content-wrapper">
                                    <h1 className='title'>EXPERIENCE ENHANCED CALL FEATURES AND MORE...</h1>
                                    <ul className="features">
                                        <li className='product-tag'>Extension Lines Dialing from your Landline Number</li>
                                        <li className='product-tag'>Automatic Call Attendant upon answering the call</li>
                                        <li className='product-tag'>Call Transferring and Forwarding to different extension lines</li>
                                        <li className='product-tag'>Call Waiting and Voicemail</li>
                                    </ul>
                                    {/* <div className="prices">
                                        <p><b>PRICE STARTS AT PHP 300 per user/ month</b></p>
                                        <p>Comes with an IP Phone starting at PHP 2,900.</p>
                                        <p>Option to add a Business Phone number at PHP 1,000/ month.</p>
                                    </div> */}
                                </div>
                            </section>
                            {/* <section className="product-video">
                                <div className="video-wrapper">
                                    <iframe className='video' src="https://www.youtube.com/embed/GhYEmXGXwns?si=uoL28cT5aI4XB3Ud" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                            </section> */}
                        </div>
                    </div>
                )
                break;
            case "cxboost":
                setArticleContent(
                    <div>
                        <Helmet>
                                <title>CXBoost: Elevate Customer Conversion with Smart Insights | Telavi</title>
                                <meta name="description" content="Enhance customer experience with CXBoost—a contact center plug-in that streamlines call management and delivers actionable insights for higher conversions." />
                                <script type="application/ld+json">{JSON.stringify(cxboost_schemaOrgJSONLD)}</script>
                        </Helmet>
                        <div className='product-main product-ctc'>
                            <section className='banner inner-page'>
                                <div className="content-wrapper">
                                    <div className="left">
                                        <Fade triggerOnce direction='left' duration={1000} className='tile tile-tl'>
                                            <img src={bannertile} className='inner-banner-tile'/>
                                        </Fade>
                                        <div className="brand">
                                            <Fade cascade direction='right' delay={300} triggerOnce duration={500}>
                                                <h1 className="title">
                                                    CXBoost
                                                </h1>
                                            </Fade>
                                        </div>
                                    </div>
                                    <div className="right imgbanner-wrapper">
                                        <Fade triggerOnce duration={1500}>
                                            <img src={require("../assets/images/products/cxboost-banner.jpg")} className='imgbanner'/>
                                        </Fade>
                                    </div>
                                </div>
                            </section>
                            <section className="product-slogan">
                                <div className="content-wrapper">
                                    <div className="left">
                                        <h1 className='title'>A Contact Centre Plug-in for Better Business Insights</h1>
                                        <p>Manage your Customer Calls Better and Deliver Better Customer Conversion.</p>
                                        <img src={require("../assets/images/products/cxboost-2.jpg")} alt="" srcSet="" />
                                    </div>
                                    <div className="right">
                                        <img src={require("../assets/images/ctc-badge.png")} alt="" srcSet="" />
                                        <ul>
                                            <li className='check-title'>
                                                <span>
                                                    HOW CAN TELAVI CXBOOST HELP YOUR BUSINESS?
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    Get Business Insights and Better Customer Handling Efficiency with real-time Wall Board Customer Analytics
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    Seamlessly Interconnect Multiple Sites to pass customer calls anywhere in the world virtually and instantaneously
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    No downtime with 99.6% Uptime Reliability on Cloud
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <section className="product-features">
                                <div className="content-wrapper">
                                    <h1 className='title'>EXPERIENCE ENHANCED CALL FEATURES AND MORE...</h1>
                                    <ul className="features">
                                        <li className='product-tag'>Real-Time Wall Board Analytics</li>
                                        <li className='product-tag'>Skill-Based Routing</li>
                                        <li className='product-tag'>Queue Call Back (Call Back Assist)</li>
                                        <li className='product-tag'>Cradle-To-Grave Insight Custom Reporting</li>
                                        <li className='product-tag'>Extension Lines Dialing</li>
                                        <li className='product-tag'>Automatic Call Attendant</li>
                                        <li className='product-tag'>Call Transferring and Forwarding Call </li>
                                        <li className='product-tag'>Waiting and Voicemail</li>
                                        <li className='product-tag'>Personalized Caller ID</li>
                                        <li className='product-tag'>Call Detail Records Access</li>
                                        <li className='product-tag'>Instant Messaging</li>
                                    </ul>
                                    {/* <div className="prices">
                                        <p><b>PRICE STARTS AT PHP 2,500 per user/ month</b></p>
                                        <p>Option to add an IP Phone starting at PHP 2,900.</p>
                                        <p>Option to add a Business Phone number at PHP 1,000/ month.</p>
                                    </div> */}
                                </div>
                            </section>
                            {/* <section className="product-video">
                                <div className="video-wrapper">
                                    <iframe className='video' src="https://www.youtube.com/embed/GhYEmXGXwns?si=uoL28cT5aI4XB3Ud" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                            </section> */}
                        </div>
                    </div>
                )
                break;
            case "link":
                setArticleContent(
                    <div>
                        <Helmet>
                                <title>Link: Seamless Calls and Meetings on a Private Highway | Telavi</title>
                                <meta name="description" content="Experience crystal-clear calls and smooth meetings with Telavi Link—a private highway for your communications, ensuring uninterrupted and secure connectivity." />
                                <script type="application/ld+json">{JSON.stringify(link_schemaOrgJSONLD)}</script>
                        </Helmet>
                        <div className='product-main product-ctc'>
                            <section className='banner inner-page'>
                                <div className="content-wrapper">
                                    <div className="left">
                                        <Fade triggerOnce direction='left' duration={1000} className='tile tile-tl'>
                                            <img src={bannertile} className='inner-banner-tile'/>
                                        </Fade>
                                        <div className="brand">
                                            <Fade cascade direction='right' delay={300} triggerOnce duration={500}>
                                                <h1 className="title">
                                                    Link
                                                </h1>
                                            </Fade>
                                        </div>
                                    </div>
                                    <div className="right imgbanner-wrapper">
                                        <Fade triggerOnce duration={1500}>
                                            <img src={require("../assets/images/products/link-banner.jpg")} className='imgbanner'/>
                                        </Fade>
                                    </div>
                                </div>
                            </section>
                            <section className="product-slogan">
                                <div className="content-wrapper">
                                    <div className="left">
                                        <h1 className='title'>Experience a Private Highway for your calls and meetings</h1>
                                        <img src={require("../assets/images/products/link-2.jpg")} alt="" srcSet="" />
                                    </div>
                                    <div className="right">
                                        <img src={require("../assets/images/ctc-badge.png")} alt="" srcSet="" />
                                        <ul>
                                            <li className='check-title'>
                                                <span>
                                                    Experience an Express Lane just for your Calls and Audio Conferences
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    <span className='check-title-inner'>Smoother Clear Connections</span><br />
                                                    Have a dedicated path for your calls and audio conferences without experiencing public internet traffic jams, choppy lines, or unclear audio
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    <span className='check-title-inner'>Enhanced Security</span><br /> Create a more secure connection, minimizing the risk of unwanted access to your communication
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    <span className="check-title-inner">Call Traffic Flow Control</span><br /> Have more control over your call traffic flows and ensure that your calls and data get priority
                                                </span>
                                            </li>
                                            {/* <li>
                                            <div className="product-features">
                                                <div className="content-wrapper">
                                                    <div className="prices">
                                                        <p><b>PRICE STARTS AT <br />PHP 500 per session</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>                
                )
                break;
            case "roam":
                setArticleContent(
                    <div>
                        <Helmet>
                                <title>Roam: Access Landline Calls Anywhere with Cloud | Telavi</title>
                                <meta name="description" content="Stay connected from anywhere with Telavi Roam—access your office landline on any device through the cloud. Seamless communication, no matter where you are." />
                                <script type="application/ld+json">{JSON.stringify(roam_schemaOrgJSONLD)}</script>
                        </Helmet>
                        <div className='product-main product-ctc'>
                            <section className='banner inner-page'>
                                <div className="content-wrapper">
                                    <div className="left">
                                        <Fade triggerOnce direction='left' duration={1000} className='tile tile-tl'>
                                            <img src={bannertile} className='inner-banner-tile'/>
                                        </Fade>
                                        <div className="brand">
                                            <Fade cascade direction='right' delay={300} triggerOnce duration={500}>
                                                <h1 className="title">
                                                    Roam
                                                </h1>
                                            </Fade>
                                        </div>
                                    </div>
                                    <div className="right imgbanner-wrapper">
                                        <Fade triggerOnce duration={1500}>
                                            <img src={require("../assets/images/products/roam-banner.jpg")} className='imgbanner'/>
                                        </Fade>
                                    </div>
                                </div>
                            </section>
                            <section className="product-slogan">
                                <div className="content-wrapper">
                                    <div className="left">
                                        <h1 className='title'>Your Business Landline On-the-Go</h1>
                                        <p>Now you can pick up your landline calls in the office from anywhere in the world, using any device, because your landline is now on Cloud.</p>
                                        <img src={require("../assets/images/products/roam-2.jpg")} alt="" srcSet="" />
                                    </div>
                                    <div className="right">
                                        <img src={require("../assets/images/ctc-badge.png")} alt="" srcSet="" />
                                        <ul>
                                            <li className='check-title'>
                                                <span>
                                                    HOW CAN TELAVI ROAM HELP YOUR BUSINESS?
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    Never miss a call from your customers and colleagues                            
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    Pick up your office calls conveniently using your preferred device —laptop, tablet, mobile phone or IP Phone — in the office, from anywhere
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    Interconnect your different business sites or branches using the same landline number (no charges for local calls internally)
                                                </span>
                                            </li>
                                            <li>
                                                <img src={require("../assets/images/check.png")} alt="" /> 
                                                <span>
                                                    No downtime with 99.6% Uptime Reliability on Cloud
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <section className="product-features">
                                <div className="content-wrapper">
                                    <h1 className='title'>EXPERIENCE ENHANCED CALL FEATURES AND MORE...</h1>
                                    <ul className="features">         
                                        <li className="product-tag">Extension Lines Dialing from your Landline Number</li>
                                        <li className="product-tag">Automatic Call Attendant upon answering the call</li>
                                        <li className="product-tag">Call Transferring and Forwarding to different extension lines</li>
                                        <li className="product-tag">Call Waiting and Voicemail</li>
                                        <li className="product-tag">Personalized Caller ID per extension line</li>
                                        <li className="product-tag">Call Detail Records Access viewed via an Online Dashboard</li>
                                        <li className="product-tag">Instant Messaging through the Telavi Elite App</li>
                                    </ul>
                                    {/* <div className="prices">
                                        <p><b>PRICE STARTS AT PHP 500 per user/ month</b></p>
                                        <p>Option to add an IP Phone starting at PHP 2,900.</p>
                                        <p>Option to add a Business Phone number at PHP 1,000/ month.</p>
                                    </div> */}
                                </div>
                            </section>
                            {/* <section className="product-video">
                                <div className="video-wrapper">
                                    <iframe className='video' src="https://www.youtube.com/embed/GhYEmXGXwns?si=uoL28cT5aI4XB3Ud" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                            </section> */}
                        </div>
                    </div>
                )
                break;
            default:
                
                break;
        }
        setLoading(false);
    }, []);


    return(
        <>
        {loading ? (<Loader />) : ("")}
        <>
        {articleContent}
        </>
        </>
    );
}